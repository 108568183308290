import BarTooltip from './BarTooltip';
import ColLabels from './ColLabels';
import { ResponsiveBar } from '@nivo/bar';

const Bar = ({barData, barValues, height, colors, width, enableGridX}) => {
  return (
    <div style={{height: `${height}px`, width}}>
      <ResponsiveBar
        tooltip={(props) => <BarTooltip {...props} originVal={barValues} />}
        height={height}
        layers={[
          "grid",
          "axes",
          "bars",
          (props) => <ColLabels {...props} originVal={barValues} />,
          "markers",
          "legends"
        ]}
        enableGridX={enableGridX}
        enableGridY={false}
        data={[barData]}
        keys={Object.keys(barData)}
        indexBy="activity_name"
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        padding={0}
        groupMode="grouped"
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        label={null}
        role="application"
        barAriaLabel={function(e){return e.id + e.indexValue}}
        colors={colors}
      />
    </div>
  );
};

export default Bar;
