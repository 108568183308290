const API_ENDPOINT = 'https://cockpit.711media.de/api/public/dashboard/270734ab-2313-40a3-a091-2d764ad893e3/card/142';
const initParameters = {
  "type": "category",
  "value": "",
  "target": ["variable", ["template-tag", "project_identifier"]]
};

const queryParams = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

initParameters.value = queryParams.value || "development-hs-reutlingen-web-relaunch";

export const fetchData = async ({cb, parameters = initParameters}) => {
  try {
    const res = await fetch(
        API_ENDPOINT + `?parameters=${JSON.stringify([parameters])}`,
    );
    const data = await res.json();
    cb(data);
  } catch (error) {
    console.warn(error);
  }
}
