import { useEffect, useState } from 'react';
import Row from './components/Row';
import { fetchData } from './api';

import './App.scss';

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData({cb: (data) => {setData(data)}});
  }, []);

  if (data) {
    const {data : { cols, rows }} = data;

    return (
      <div className="App">
        {rows.map((row, i) => <Row key={i} index={i} cols={cols} row={row} />)}
      </div>
    );
  }

  return (
    <h3>Loading...</h3>
  );
}

export default App;
