export const barValueColor = (value) => {

  let percentageColor = '#c05252';
  if (value <= 60) {
    percentageColor = '#5C9747';
  } else if (value <= 80) {
    percentageColor = 'rgba(210,144,71,0.65)';
  }

  return percentageColor;
}