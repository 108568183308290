import { BasicTooltip } from '@nivo/tooltip';

const BarTooltip = ({ id, color, originVal }) => {
  const currentValue = Math.round((originVal[id] + Number.EPSILON) * 100) / 100;
  const currency = id === 'percentage' ? '%' : '';

  return (
    <BasicTooltip
      id={id}
      value={currentValue + currency}
      color={color}
      enableChip
    />
  );
};

export default BarTooltip;