import Bar from './Bar';
import { COL_HEIGHT, PERCENTAGE_KEY } from '../utils/constants';
import { barValueColor } from '../utils';

const Row = ({ cols, row, index }) => {
  const barValues = cols.reduce((acc, col, i) => {
    return {...acc, [col.name]: row[i]};
  }, {});
  const barData = {
    budget_hours: Math.abs(barValues['budget_hours']),
    spent_hours: Math.abs(barValues['spent_hours'])
  };

  return (
    <>
      <div className="Row">
        <h3 className="RowTitle">{row[0]}</h3>
        <div className="BarWrapper">
          <Bar
            barData={barData}
            barValues={barValues}
            height={COL_HEIGHT * Object.keys(barData).length}
            width={'100%'}
            colors={['#2D588C', barValueColor(barValues[PERCENTAGE_KEY])]}
            enableGridX={true}
          />
        </div>
      </div>
      {!index && <hr />}
    </>
  );
};

export default Row;
