const ColLabels = ({ bars, originVal }) => {
  return bars.map(({ data: {value, id}, x, width, height, absY }, i) => {
    const currentValue = Math.round((originVal[id] + Number.EPSILON) * 100) / 100;
    const rightOffset = currentValue % 1 ? 10 : 0;
    const currency = id === 'percentage' ? '%' : '';

    return (
      <g
        transform={`translate(${-24}, ${absY + height / 2})`}
        key={`${value}-${i}`}
      >
        <text
          fontSize={12}
          className="bar-total-label"
          x={x + width - rightOffset}
          y={0}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {currentValue + currency}
        </text>
      </g>
    );
  });
};

export default ColLabels;